var DroneLib = (function() {
    var b = (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/iPad/i)),
        f = ((window.retina || window.devicePixelRatio > 1) && !navigator.userAgent.match(/iPhone/i) && !navigator.userAgent.match(/iPod/i)),
        c = [],
        i = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var h = navigator.userAgent.toLowerCase(),
        d = (h.indexOf("iphone") > -1),
        e = (h.indexOf("ipad") > -1),
        a = (h.indexOf("android") > -1),
        j = (h.indexOf("chrome") > -1),
        g = (window.retina || window.devicePixelRatio > 1);
    return {
        isRetina: g,
        isiPhone: d,
        isiPad: e,
        isAndroid: a,
        isMobile: (d || e || a),
        isChrome: j,
        isBigRetina: (g && !d && !a),
        isModernBrowser: (Modernizr.csstransitions && Modernizr.csstransforms && (!a || j)),
        now: function() {
            return Date.now ? Date.now() : (new Date()).getTime()
        },
        randomRange: function(l, k) {
            return Math.floor((Math.random() * (k - l + 1))) + l
        },
        emailRegexp: function() {
            return i
        },
        scheduleNextFrame: function(l, k) {
            var m = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
            if (m) {
                m(l)
            } else {
                if (k) {
                    setTimeout(function() {
                        l(DroneLib.now())
                    }, 20)
                }
            }
        },
        addEvent: function(m, k, l) {
            if (m.addEventListener) {
                m.addEventListener(k, l, false)
            } else {
                if (m.attachEvent) {
                    m.attachEvent("on" + k, l)
                }
            }
            c.push({
                el: m,
                name: k,
                fn: l
            })
        },
        removeEvent: function(l, k) {
            c.some(function(n, m) {
                if (n.el == l && n.name == k) {
                    if (l.removeEventListener) {
                        l.removeEventListener(k, n.fn, false)
                    } else {
                        if (l.detachEvent) {
                            l.detachEvent("on" + k, n.fn)
                        }
                    }
                    c.splice(m, 1);
                    return true
                }
            })
        },
        parsePath: function(u) {
            var o = [],
                t, s, q, m, r, p, k;
            for (t = 0, s = u.length; t < s; t++) {
                r = u[t].split(";");
                for (q = 0, m = r.length; q < m; q++) {
                    p = r[q].split(",");
                    k = p.shift();
                    o.push({
                        c: k,
                        p: DroneLib.arrToFloatArr(p)
                    })
                }
                o.push({
                    c: "c",
                    p: []
                })
            }
            return o
        },
        addPath: function(m, q, p, r) {
            var o, k, n;
            if (!p) {
                p = 0
            }
            if (!r) {
                r = 0
            }
            for (o = 0, k = q.length; o < k; o++) {
                n = q[o];
                if (n.c == "m") {
                    m.moveTo(n.p[0] + p, n.p[1] + r)
                } else {
                    if (n.c == "l") {
                        m.lineTo(n.p[0] + p, n.p[1] + r)
                    } else {
                        if (n.c == "p") {
                            m.bezierCurveTo(n.p[0] + p, n.p[1] + r, n.p[4] + p, n.p[5] + r, n.p[2] + p, n.p[3] + r)
                        } else {
                            if (n.c == "c") {
                                m.closePath()
                            }
                        }
                    }
                }
            }
        },
        arrayToRGB: function(k) {
            if (k.length > 3 && k[3] != 100) {
                return "rgba(" + (k.slice(0, 3).join(", ")) + ", " + (k[3] / 100) + ")"
            }
            return "rgb(" + (k.slice(0, 3).join(", ")) + ")"
        },
        linearInterpolateArrars: function(n, m, r) {
            var q = [],
                p, k;
            for (p = 0, k = n.length; p < k; p++) {
                q.push(Math.round(n[p] + ((m[p] - n[p]) * r)))
            }
            return q
        },
        arrToFloatArr: function(k) {
            var p = [],
                n, m;
            for (n = 0, m = k.length; n < m; n++) {
                p.push(parseFloat(k[n], 10))
            }
            return p
        },
        hexColorToArr: function(k) {
            return [parseInt(k.substr(1, 2), 16), parseInt(k.substr(3, 2), 16), parseInt(k.substr(5, 2), 16)]
        },
        getCSSTransformPropertyName: function() {
            return {
                WebkitTransform: "-webkit-transform",
                MozTransform: "-moz-transform",
                OTransform: "-o-transform",
                msTransform: "-ms-transform",
                transform: "transform"
            }[Modernizr.prefixed("transform")]
        }
    }
}());