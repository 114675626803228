var mobileToggler = document.querySelector('.site-header .mobile-toggler');
var headerDiv = document.querySelector('.site-header');
var footerDiv = document.querySelector('.site-footer');
var heroCarousel = document.querySelector('.herospace');
var solutionCarousel = document.querySelector('.solutions-gallery');
var solutionArrows = document.querySelectorAll('.solutions-gallery-wrapper .solutions-arrow');
var pCategories = document.querySelectorAll('.portfolio-tags .btn');
var homeCanvas = document.getElementById('bgCanvas');

// Mobile menu
if (headerDiv && footerDiv && mobileToggler) {
    window.addEventListener('resize', function () {
        if (document.documentElement.clientWidth < 1025) {
            removeClass('.site-header', 'hoverable');
        } else {
            addClass('.site-header', 'hoverable');
        }
    });

    window.addEventListener('load', function () {
        if (document.documentElement.clientWidth < 1025) {
            removeClass('.site-header', 'hoverable');
        }
    });

    mobileToggler.addEventListener('click', function () {
        toggleClass(headerDiv, 'is-open');
        toggleClass(footerDiv, 'is-open');
    });
}

// Header menu scroll events
window.addEventListener('scroll', function () {
    if (this.scrollY > 88) {
        addClass(headerDiv, 'is-closed');
    } else {
        removeClass(headerDiv, 'is-closed');
    }
});

// Herospace carousel
if (heroCarousel) {
    var heroFlickity = new Flickity(heroCarousel, {
        autoPlay: 4000,
        wrapAround: true,
        prevNextButtons: false,
        cellSelector: '.hero-slide',
        pageDots: true
    });
}

// Solution carousel
if (solutionCarousel) {
    var solFlickity = new Flickity(solutionCarousel, {
        autoPlay: false,
        wrapAround: true,
        prevNextButtons: false,
        cellSelector: '.solution-slide',
        pageDots: false
    });

    if (solutionArrows) {
        forEach(solutionArrows, function (index, elem) {
            elem.addEventListener('click', function () {
                if (hasClass(elem, 'arrow-prev')) {
                    solFlickity.previous();
                } else {
                    solFlickity.next();
                }
            });
        });
    }
}

// Portfolio tags
if (pCategories) {
    forEach(pCategories, function (index, elem) {
        elem.addEventListener('click', function () {
            var thisId = elem.dataset.category;
            window.location.href = '/portfolio/?category=' + thisId;
        });
    });
}

// Background canvas animation
if (hasClass(document.body, 'is-home')) {
    var bgCanvas = new BGCanvas(homeCanvas);
    bgCanvas.start();
}
