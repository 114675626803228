function BGCanvas(elem) {
    this.rangeDistributeRatio = 1.1;
    this.wandererCountRange = [30, 40];
    this.wandererSeedRange = [0.6, 1.3];
    this.wandererBodyAlphaRange = [0.5, 1];
    this.wandererLineAlphaRange = [0.16, 0.33];
    this.wandererDirectionBorder = 150;
    this.globalAlphaStep = 0.015;
    this.easeStep = 0.003;
    this.wanderTime = 8.5 * 1000;
    this.stopTime = 8.6 * 1000;
    this.bgColor = "#d3d3d3";
    this._canvas = elem;
    this._ctx = elem.getContext("2d");
    this._tickCount = 0;
    this._state = "on";
    this._globalAlpha = 0;
    this._wanderers = [];
    this._scale = window.devicePixelRatio || 1;
    this._bindedTick = this.tick.bind(this);
    this._bindedOnWanderingTimeout = this.onWanderingTimeout.bind(this);
    this._bindedOnStoppingTimeout = this.onStoppingTimeout.bind(this);
    window.addEventListener("resize", this.onWindowSize.bind(this));
    this.onWindowSize()
}

BGCanvas.prototype.onWindowSize = function() {
    var x = window.outerWidth;
    var y = window.outerHeight;
    this._canvas.set('width', this._canvasWidth = x * this._scale);
    this._canvas.set('height', this._canvasHeight = y * this._scale);
};

BGCanvas.prototype.start = function() {
    var c, a, b, j, e;
    var h = DroneLib.randomRange(this.wandererCountRange[0], this.wandererCountRange[1]),
        d = Math.min(this._canvasWidth, this._canvasHeight) * this.rangeDistributeRatio,
        g = DroneLib.randomRange(this._canvasWidth / 2 - d, this._canvasWidth / 2 + d),
        f = DroneLib.randomRange(this._canvasHeight / 2 - d, this._canvasHeight / 2 + d);
    this._canvas.addClass("show");
    this._wanderers = [];
    for (c = 0; c < h; c++) {
        a = {
            x: g,
            y: f,
            connWanderer: b,
            bodyAlpha: DroneLib.randomRange(this.wandererBodyAlphaRange[0] * 100, this.wandererBodyAlphaRange[1] * 100) / 100
        };
        this._wanderers.push(a);
        b = a;
        j = DroneLib.randomRange(0, d), e = DroneLib.randomRange(0, d);
        g += (g < this._canvasWidth / 2) ? j : 0 - j;
        f += (f < this._canvasHeight / 2) ? e : 0 - e
    }
    this._wanderers[0].connWanderer = b;
    this.initWanderers();
    this.tick();
    setTimeout(this._bindedOnWanderingTimeout, this.wanderTime)
};

BGCanvas.prototype.calcDirection = function(b, a) {
    if (b >= a - this.wandererDirectionBorder) {
        return -1
    }
    if (b <= this.wandererDirectionBorder) {
        return 1
    }
    return DroneLib.randomRange(0, 1) ? -1 : 1
};

BGCanvas.prototype.calcSpeed = function() {
    return DroneLib.randomRange(this.wandererSeedRange[0] * 100, this.wandererSeedRange[1] * 100) / 100
};

BGCanvas.prototype.initWanderers = function() {
    var c, b, a;
    for (c = 0, b = this._wanderers.length; c < b; c++) {
        a = this._wanderers[c];
        a.xspeed = this.calcSpeed();
        a.yspeed = this.calcSpeed();
        a.xdir = this.calcDirection(this._wanderers[c].x, this._canvasWidth);
        a.ydir = this.calcDirection(this._wanderers[c].y, this._canvasHeight)
    }
    this._ease = 0;
    this._easeDir = 1
};

BGCanvas.prototype.stopWanderers = function() {
    this._easeDir = -1
};

BGCanvas.prototype.onWanderingTimeout = function() {
    this._toState = "off";
    setTimeout(this._bindedOnStoppingTimeout, this.stopTime)
};

BGCanvas.prototype.onStoppingTimeout = function() {
    this._toState = "on";
    setTimeout(this._bindedOnWanderingTimeout, this.wanderTime)
};

BGCanvas.prototype.tick = function(d) {
    var c, b, a;
    DroneLib.scheduleNextFrame(this._bindedTick);
    if (++this._tickCount % 2 != 0) {
        return
    }
    if (this._tickCount == 1000000) {
        this._tickCount = 0
    }
    if (this._toState != this._state) {
        this._state = this._toState;
        if (this._state == "on") {
            this.initWanderers()
        } else {
            if (this._state == "off") {
                this.stopWanderers()
            }
        }
    }
    if ((this._easeDir == 1 && this._ease < 1) || (this._easeDir == -1 && this._ease > 0)) {
        this._ease += this.easeStep * this._easeDir
    } else {
        this._ease = (this._easeDir == 1) ? 1 : 0
    }
    for (c = 0, b = this._wanderers.length; c < b; c++) {
        a = this._wanderers[c];
        a.x += a.xspeed * a.xdir * this._ease;
        a.y += a.yspeed * a.ydir * this._ease
    }
    if (this._state != "off" || this._ease != 0) {
        this.draw()
    }
};

BGCanvas.prototype.draw = function() {
    var d, b, a, c;
    this._ctx.save();
    this._ctx.fillStyle = this.bgColor;
    this._ctx.beginPath();
    this._ctx.fillRect(0, 0, this._canvasWidth, this._canvasHeight);
    this._ctx.globalAlpha = this._globalAlpha;
    if (this._globalAlpha < 1) {
        this._globalAlpha += this.globalAlphaStep
    }
    c = ((Math.round(((1 - this._ease) * (this.wandererLineAlphaRange[1] - this.wandererLineAlphaRange[0]) * 1000)) / 1000) + this.wandererLineAlphaRange[0]);
    this._ctx.strokeStyle = "rgba(255, 255, 255, " + c + ")";
    this._ctx.lineWidth = 1;
    for (d = 0, b = this._wanderers.length; d < b; d++) {
        this._ctx.beginPath();
        a = this._wanderers[d];
        this._ctx.moveTo(a.x, a.y);
        this._ctx.lineTo(a.connWanderer.x, a.connWanderer.y);
        this._ctx.stroke();
        this._ctx.fillStyle = "rgba(255, 255, 255, " + a.bodyAlpha + ")";
        this._ctx.fillRect(a.x - 2, a.y - 2, 4, 4)
    }
    this._ctx.restore()
};
