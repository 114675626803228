var forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]);
    }
};

var showElem = function (elem) {
    if (elem) {
        elem.style.display = 'block';
    }
};

var showByClass = function (cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function (index, elem) {
            elem.style.display = 'block';
        });
    }
};

var hideElem = function (elem) {
    elem.style.display = 'none';
};

var hideByClass = function (cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function (index, elem) {
            elem.style.display = 'none';
        });
    }
};

var toggleElem = function (elem) {
    if (elem.style.display == 'block') {
        elem.style.display = 'none';
    } else {
        elem.style.display = 'block';
    }
};

var toggleByClass = function (cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function (index, elem) {
            if (elem.style.display == 'block') {
                elem.style.display = 'none';
            } else {
                elem.style.display = 'block';
            }
        });
    }
};

var hasClass = function (elem, cl) {
    return new RegExp(' ' + cl + ' ').test(' ' + elem.className + ' ');
};

var addClass = function (selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector;

        if (!hasClass(elem, cl)) {
            elem.className == '' ? elem.className = cl : elem.className += ' ' + cl;
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function (index, elem) {
                if (!hasClass(elem, cl)) {
                    elem.className == '' ? elem.className = cl : elem.className += ' ' + cl;
                }
            });
        }
    }

    return null;
};

var removeClass = function (selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector;
        var newcl = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';

        if (hasClass(elem, cl)) {
            while (newcl.indexOf(' ' + cl + ' ') >= 0) {
                newcl = newcl.replace(' ' + cl + ' ', ' ');
            }
            elem.className = newcl.replace(/^\s+|\s+$/g, '');
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function (index, elem) {
                var newcl = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';

                if (hasClass(elem, cl)) {
                    while (newcl.indexOf(' ' + cl + ' ') >= 0) {
                        newcl = newcl.replace(' ' + cl + ' ', ' ');
                    }
                    elem.className = newcl.replace(/^\s+|\s+$/g, '');
                }
            });
        }
    }

    return null;
};

var toggleClass = function (selector, cl) {
    if (typeof selector === 'object') {
        if (selector.length > 0) {
            forEach(selector, function (index, elem) {
                hasClass(elem, cl) ? removeClass(elem, cl) : addClass(elem, cl);
            });
        } else {
            hasClass(selector, cl) ? removeClass(selector, cl) : addClass(selector, cl);
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function (index, elem) {
                hasClass(elem, cl) ? removeClass(elem, cl) : addClass(elem, cl);
            });
        }
    }

    return null;
};

var animate = function (elem, style, unit, from, to, time, prop) {
    var start = new Date().getTime(),
        timer = setInterval(function () {
            var step = Math.min(1, (new Date().getTime() - start) / time);
            if (prop) {
                elem[style] = (from + step * (to - from)) + unit;
            } else {
                elem.style[style] = (from + step * (to - from)) + unit;
            }
            if (step == 1) clearInterval(timer);
        }, 25);

    elem.style[style] = from + unit;
};


var isValid = function (email) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(email);
};

var parseURL = function (url) {
    var parser = document.createElement('a'),
        searchObject = {},
        queries, split, i;

    parser.href = url;
    queries = parser.search.replace(/^\?/, '').split('&');

    for (i = 0; i < queries.length; i++) {
        split = queries[i].split('=');
        searchObject[split[0]] = split[1];
    }

    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash
    };
};

var vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
var vpHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
